import React from "react";
import "./Promo.scss";
import promoimg from "../../Media/promo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const Promo = () => {
  const promodata = [
    "Rumah Siap Huni",
    "Tanpa DP",
    "100% KPR Approved",
    "Diskon Ratusan Juta Rupiah!",
    "Logam Mulia 200 Gram",
    "Hyundai Ioniq 5",
    "Free Lifetime Membership Clubhouse",
    "100% Friendly Eco Living - Bebas Polusi",
    "100% Friendly Eco Living - Bebas Sampah",
  ];

  return (
    <div className="promocont">
      <div className="promocenter">
        <div className="promocontent">
          <div id="promo" className="title">
            PROMO
          </div>
          <div className="promopoint">
            {promodata.map((item, index) => (
              <div className="point" key={index}>
                <FontAwesomeIcon size="2x" icon={faCheck} />
                &nbsp;
                {item}
              </div>
            ))}
          </div>
          *T&C Apply
          <div className="promobutton">
            <button
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=6283110558510&text=Halo%20Dimas%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo)%20(https://www.podomoropark-bdg.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                  "_blank"
                )
              }
              className="wabutton"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; Get More Promo
            </button>
          </div>
        </div>
        <div className="promoimg">
          <img alt="promopodomrobandung" className="img" src={promoimg} />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Promo;
