import React from "react";
import "./New.scss";
import banner from "../../Media/New Launching/banner.png";
import data from "./data.json";
import Card from "../../Component/Card/Card";
import avanyaimg from "../../Media/New Launching/Avanya";
import avegaimg from "../../Media/New Launching/Avega";
import Studenthouse from "./studenthouse/studenthouse.jsx";
import nuananta from "./ananta-dekstop.webp";
import mobilenuananta from "./ananta-mobile.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const New = () => {
  const imageMap = {
    avanyaimg,
    avegaimg,
  };
  return (
    <div className="newcont">
      <div className="newcenter">
        <div className="title">
          New Launching <br /> Nu Ananta
        </div>
        <div id="nuananta" className="nuananta">
          <img className="dekstop" src={nuananta} alt="nuananta" />
          <img className="mobile" src={mobilenuananta} alt="mobilenuananta" />
        </div>
        <div className="card-button">
          <button
            className="button"
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=6283110558510&text=Halo%20Dimas%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(nuananta)%20(https://www.podomoropark-bdg.com/)%20?&fbclid=PAAaa7g9IJ-tB3RyMunLD6w_5ysqP4oI6PA2d9Ml7Isc1_OXjeOiGuAqu7Ap0",
                "_blank"
              )
            }
          >
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Whatsapp
          </button>
        </div>
        <Studenthouse />
        <div id="avanya" className="subtitle">
          Avanya & Avega at Patragriya
        </div>
        <div className="banner">
          <img className="bannerimg" alt="newlaunching" src={banner} />
        </div>
        <div className="cardsec">
          {data.cards.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Card
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                harga={card.harga}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default New;
